import React from 'react';

import { Modal, message , Row, Col, Form, Input, Button, Select} from 'antd';



import {getTypeArticleOption} from "../util/Toolbox";

import './css/home.css';
import conseils from './conseils.json';

import {
  EditOutlined
} from '@ant-design/icons';

import BOUTON_ANALYSE             from './assets/home/BOUTON_ANALYSE.png';
import BOUTON_CALCUL_VOLUME       from './assets/home/BOUTON_CALCUL_VOLUME.png';
import BOUTON_HISTORIQUE_ANALYSE  from './assets/home/BOUTON_HISTORIQUE_ANALYSE.png';
import BOUTON_GOUTTE              from './assets/home/BOUTON_PRODUITS.png';

import './css/navBar.css';
import navBarBackGround from './assets/navBar/footer_vague_bleue.svg';
import navBarPicto_analyse from './assets/navBar/picto_analyse.png';
import navBarPicto_calcul_volume_eau from './assets/navBar/picto_calcul_volume_eau.png';
import navBarPicto_home from './assets/navBar/picto_home.png';
import navBarPicto_Historique from './assets/navBar/picto_historique.png';
import navBarPicto_Revendeur from './assets/navBar/picto_revendeur.png';


class Home extends React.Component {

  state = {
    conseil_LIST : []
  }


  async componentDidMount() {

    let conseil_LIST = [];

    let conseil = {

    }


    conseil_LIST.push(conseil);


    this.setState({conseil_LIST});

  }


  /*
  <Col style={{ cursor: 'pointer'}} className="" span={12} onClick= {this.validationAUTREFORME}>
    <div className= "homeMenuCell">
      <img  style ={{marginTop:25}} src={BOUTON_GOUTTE} />
    </div>
  </Col>
  */


  render() {

      return (

        <div>

          <div className = "wrapper_home">

          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>

          <div className = "wrapper_home_header">

          </div>


          <div className = "containersHome">

            <Row style = {{marginTop:12}} gutter={[0, 0]}>

              <Col style={{ cursor: 'pointer'}} className="" span={12} onClick= {this.validationRECTANGULAIRE}>
                <div  className= "homeMenuCell">
                  <a href = "/forms"><img loading="lazy"  src={BOUTON_ANALYSE} width ="180px"  height ="180px" /></a>
                </div>
              </Col>

              <Col style={{ cursor: 'pointer'}} className="" span={12} onClick= {this.validationOVALE}>
                <div className= "homeMenuCell">
                  <a href = "/simulateur"><img  loading="lazy" src={BOUTON_CALCUL_VOLUME}  width ="180px"  height ="180px" /></a>
                </div>
              </Col>

              <Col style={{ cursor: 'pointer'}} className="" span={12} onClick= {this.validationRONDE}>
                <div className= "homeMenuCell">
                  <a href = "/historique"><img loading="lazy"  src={BOUTON_HISTORIQUE_ANALYSE}   width ="180px"  height ="180px" /></a>
                </div>
              </Col>

              <Col style={{ cursor: 'pointer'}} className="" span={12}>
                <div className= "homeMenuCell">
                  <a href = "/analyseProduitsAll"><img loading="lazy"  src={BOUTON_GOUTTE}  width ="180px"  height ="180px" /></a>
                </div>
              </Col>

            </Row>

          <div className = "home_titre">
            <p>Les conseils Purissim'eau</p>
          </div>

          <div className="scrolling-wrapper-flexbox">

          {
            conseils.map((item) => (
              (
                  <div className="card">
                  <span>
                    <a style = {{color:'#0093CF'}} className = "colorCard" target="_blank" href={item.url}>
                      <img loading="lazy" src={require(`./assets/conseils/${item.nameImg}`)} alt=""/>
                      {item.libelle}
                    </a>
                  </span>
                  </div>
              )

              )
            )
          }
          </div>


          </div>

        </div>

        <div style = {{color:'white', marginTop:10}}>
          <p>fin</p>
        </div>








        <div style={{ backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundImage: `url(${navBarBackGround})`    }} className="navbar">
              <div className="container1">
                <div className="element1"><a href="/forms" className="active"><img loading="lazy"  width="50px" height="50px" src={navBarPicto_analyse} /></a></div>
                <div className="element1"><a href="/simulateur"><img loading="lazy"  width="50px" height="50px" src={navBarPicto_calcul_volume_eau} /></a></div>
                <div className="element1"><a href="/"><img loading="lazy"  width="50px" height="50px" src={navBarPicto_home} /></a></div>
                <div className="element1"><a href="/historique"><img loading="lazy"  width="50px" height="50px" src={navBarPicto_Historique} /></a></div>
                <div className="element1">  <a target="_blank" href="https://www.hydrapro.fr/app/uploads/storelocator0106/wordpress/hydrapro/index.html">  <img loading="lazy"  width="50px" height="50px" src={navBarPicto_Revendeur} /></a></div>
              </div>
        </div>


        <div style = {{color: 'white', marginTop:50, marginBottom:50}}>
          <p>space</p>
        </div>

        </div>

      )

    };
}


export default Home;
