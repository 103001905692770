import React from 'react';
import { Layout, Menu, Breadcrumb } from 'antd';
import { Link, Outlet } from 'react-router-dom';
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
} from '@ant-design/icons';

const { Header, Content, Footer } = Layout;

const AvaApp = ({ children }) => (

  <Layout>
    <Layout >
      <Content>
        <Outlet />
      </Content>
    </Layout>
  </Layout>

);

export default AvaApp;
