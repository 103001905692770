import React from 'react';

import {  Table, Tag ,Modal, message , Auxiliary, Row, Col, Form, Input, Button, Select, Space, Slider, Checkbox, InputNumber} from 'antd';

import {getTypeArticleOption} from "../util/Toolbox";

import './css/simulateurVolume.css';
import './css/navBar.css';

import piscineRectangulaire from './assets/simulateurVolume/piscine-rectangle.svg';
import piscineRonde from './assets/simulateurVolume/piscine-ronde.svg';
import piscineOvale from './assets/simulateurVolume/piscine-ovale.svg';
import piscineAutreForme from './assets/simulateurVolume/piscine-autre-forme.svg';
import piscineSchema from './assets/simulateurVolume/piscine-schema.svg';

import iconeHeader from './assets/simulateurVolume/picto_calcul_volume.svg';
import goutte_eau_header from './assets/analyseFormulaire/goutte_eau_header.svg';

import navBarBackGround from './assets/navBar/footer_vague_bleue.svg';
import navBarPicto_analyse from './assets/navBar/picto_analyse.png';
import navBarPicto_calcul_volume_eau from './assets/navBar/picto_calcul_volume_eau.png';
import navBarPicto_home from './assets/navBar/picto_home.png';
import navBarPicto_Historique from './assets/navBar/picto_historique.png';
import navBarPicto_Revendeur from './assets/navBar/picto_revendeur.png';

const FORME_RECTANGULAIRE = 'RECTANGULAIRE';
const FORME_RONDE         = 'RONDE';
const FORME_OVALE         = 'OVALE';
const FORME_AUTREFORME    = 'AUTREFORME';

class SimulateurVolume extends React.Component {

  state = {
      IsShowResult : false,
      ResultatAnalyse : '',
      TypeForme : FORME_RECTANGULAIRE,
      Longueur : '',
      Largeur : '',
      ProfondeurMini : '',
      ProfondeurMaxi : '',
      Diametre : '',
  }


  async componentDidMount() {

  }


  // Bouton simulateur
  validationSimulateur = () => {

    let volume = 0;

    //Control des champs
    if(this.state.TypeForme == ''){
      message.warning('Sélectionner le type de forme de votre bassin');
      return
    }

    let profondeurMoyenne = (this.state.ProfondeurMini + this.state.ProfondeurMaxi) / 2;

    if(this.state.TypeForme == FORME_RONDE){

      volume = (this.state.Diametre * this.state.Diametre * profondeurMoyenne * 0.78);
    }


    if(this.state.TypeForme == FORME_RECTANGULAIRE){
      if(!this.state.Longueur > 0){
        message.warning("Entrer la longueur du bassin")
        return;
      }
      if(!this.state.Largeur > 0){
        message.warning("Entrer la largeur du bassin")
        return;
      }
      if(!this.state.ProfondeurMini > 0){
        message.warning("Entrer la profondeur minimum du bassin")
        return;
      }
      if(!this.state.ProfondeurMaxi> 0){
        message.warning("Entrer la profondeur maximum du bassin")
        return;
      }

      volume = this.state.Longueur * this.state.Largeur * profondeurMoyenne;
      console.log(volume);
    }


    if(this.state.TypeForme == FORME_OVALE){
      if(!this.state.Longueur > 0){
        message.warning("Entrer la longueur du bassin")
        return;
      }
      if(!this.state.Largeur > 0){
        message.warning("Entrer la largeur du bassin")
        return;
      }
      if(!this.state.ProfondeurMini > 0){
        message.warning("Entrer la profondeur minimum du bassin")
        return;
      }
      if(!this.state.ProfondeurMaxi> 0){
        message.warning("Entrer la profondeur maximum du bassin")
        return;
      }

      volume = this.state.Longueur * this.state.Largeur * profondeurMoyenne * 0.89;
      console.log(volume);
    }


    if(this.state.TypeForme == FORME_AUTREFORME){
      if(!this.state.Longueur > 0){
        message.warning("Entrer la longueur du bassin")
        return;
      }
      if(!this.state.Largeur > 0){
        message.warning("Entrer la largeur du bassin")
        return;
      }
      if(!this.state.ProfondeurMini > 0){
        message.warning("Entrer la profondeur minimum du bassin")
        return;
      }
      if(!this.state.ProfondeurMaxi> 0){
        message.warning("Entrer la profondeur maximum du bassin")
        return;
      }

      volume = this.state.Longueur * this.state.Largeur * profondeurMoyenne;
      console.log(volume);
    }

    this.setState({IsShowResult : true, ResultatAnalyse : volume.toFixed()})

  };


  // Bouton vers analyse
  validationToSimulateur = () => {
    window.location.href= '/forms?volume='+ this.state.ResultatAnalyse;
  };

  //Click RECTANGULAIRE
  validationRECTANGULAIRE = () => {
    this.setState({TypeForme : FORME_RECTANGULAIRE})
  };

  //Click OVALE
  validationOVALE = () => {
    this.setState({TypeForme : FORME_OVALE})
  };

  //Click RONDE
  validationRONDE = () => {
    this.setState({TypeForme : FORME_RONDE})
  };

  //Click AUTRE FORME
  validationAUTREFORME = () => {
    this.setState({TypeForme : FORME_AUTREFORME})
  };


  render() {

      return (


      <div>

        <div className = "wrapper_simulateur">

          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>

          <div className = "wrapper_analyse_header">

            <img loading="lazy"  src={iconeHeader} />

            <p className = "wrapper_analyse_header_Title">Calcul du volume</p>

            <div className = "header_image_goutte">
              <img loading="lazy" src={goutte_eau_header} />
            </div>

          </div>




        <div className ="containersSimulateurFormulaire">

          <p className ="CalculerVolumeTitre1">Calculer le volume d'eau</p>
          <p className ="CalculerVolumeTitre2">de votre piscine ou SPA m³</p>
          <p className= "CalculerVolumeTitre"><span>Forme</span> de votre bassin :</p>

          <Row gutter={[16, 0]}>
            <Col style={{ cursor: 'pointer'}} className="gutter-row" span={12} onClick= {this.validationRECTANGULAIRE}>
              <div style = {{backgroundColor: this.state.TypeForme == FORME_RECTANGULAIRE ?  '#71c5f0' : '#B2D8EB'}}   className= "simulateurFormeCell">
                <img loading="lazy"  width="100" height="100" src={piscineRectangulaire} />
                <p style= {{marginTop: -10, paddingBottom: 16}}>{"PISCINE\nRECTANGULAIRE"}</p>
              </div>
            </Col>
            <Col style={{ cursor: 'pointer'}} className="gutter-row" span={12} onClick= {this.validationOVALE}>
              <div style = {{backgroundColor: this.state.TypeForme == FORME_OVALE ?  '#71c5f0' : '#B2D8EB'}} className= "simulateurFormeCell">
                <img loading="lazy" width="100" height="100"  src={piscineOvale} />
                <p style= {{marginTop: -10, paddingBottom: 16}}>  {"PISCINE\nOVALE"}</p>
              </div>
            </Col>
            <Col style={{ cursor: 'pointer'}} className="gutter-row" span={12} onClick= {this.validationRONDE}>
              <div style = {{backgroundColor: this.state.TypeForme == FORME_RONDE ?  '#71c5f0' : '#B2D8EB'}} className= "simulateurFormeCell">
                <img loading="lazy" width="100" height="100" src={piscineRonde} />
                <p  style= {{marginTop: -10, paddingBottom: 16}}>{"PISCINE\nRONDE"}</p>
              </div>
            </Col>
            <Col style={{ cursor: 'pointer'}} className="gutter-row" span={12} onClick= {this.validationAUTREFORME}>
              <div style = {{backgroundColor: this.state.TypeForme == FORME_AUTREFORME?  '#71c5f0' : '#B2D8EB'}} className= "simulateurFormeCell">
                <img loading="lazy"  width="100" height="100"  src={piscineAutreForme} />
                <p  style= {{marginTop: -10, paddingBottom: 16}}>{"PISCINE\nAUTRE-FORME"}</p>
              </div>
            </Col>
          </Row>


          <p className= "CalculerVolumeTitre"><span>Dimensions</span> de votre bassin :</p>

          <div className = "dimension_bassin">

            <Row  gutter={[16, 16]}>

              {
                this.state.TypeForme != FORME_RONDE
                &&
                (
                  <Col sm={12} xs={12}>
                    <p>Longueur (m)</p>
                    <InputNumber style = {{width:'80%', marginTop : -5}} value = {this.state.Longueur} onChange={(event) => this.setState({Longueur: event})}  className = "input_calculVolume" placeholder="" />
                  </Col>
                )
              }

              {
                this.state.TypeForme != FORME_RONDE
                &&
                (
                  <Col sm={12} xs={12}  >
                    <p>Largeur (m) </p>
                    <InputNumber style = {{width:'80%', marginTop : -5}} value = {this.state.Largeur} onChange={(event) => this.setState({Largeur: event})} className = "input_calculVolume" placeholder="" />
                  </Col>
                )
              }

              {
                this.state.TypeForme == FORME_RONDE
                &&
                (
                  <Col sm={12} xs={12}>
                    <p>Diamètre (m)</p>
                    <InputNumber style = {{width:'80%', marginTop : -5}} value = {this.state.Diametre} onChange={(event) => this.setState({Diametre: event})}  className = "input_calculVolume" placeholder="" />
                  </Col>
                )
              }

              <Col sm={12} xs={12} >
                <p>Profondeur mini (m)</p>
                <InputNumber style = {{width:'80%', marginTop : -5}} value = {this.state.ProfondeurMini}  onChange={(event) => this.setState({ProfondeurMini: event})} className = "input_calculVolume" placeholder="" />
              </Col>

              <Col sm={12} xs={12}  >
                <p>Profondeur max (m)</p>
                <InputNumber style = {{width:'80%', marginTop : -5}} value = {this.state.ProfondeurMaxi} onChange={(event) => this.setState({ProfondeurMaxi: event})} className = "input_calculVolume" placeholder="" />
              </Col>
            </Row>

            {
                this.state.IsShowResult == true &&
                (
                  <div style = {{marginBottom:30}} className = "result_simulateur">

                    <p className= "titre1">Votre piscine à un volume d'eau de :</p>
                    <p className= "titre2">{this.state.ResultatAnalyse + " m³"}</p>

                    <div className = "">
                      <Button className = 'buttonFormStyle'  onClick={this.validationToSimulateur} size={"large"} style={{fontWeight:600, paddingLeft:40, paddingRight:40, marginBottom: 16, backgroundColor: '#0093CF', color:'white'}}>Analyser</Button>
                    </div>

                  </div>
                )
            }

            {
              this.state.IsShowResult == false &&
              (
                <div className= "image_schema">
                  <img loading="lazy" src={piscineSchema} />
                </div>
              )
            }


            <div style = {{display:'none'}} className= "image_schema">
              <img loading="lazy" src={piscineAutreForme} />
            </div>

            {
              this.state.IsShowResult == false &&
              <div style = {{marginTop:10}}>
                <Button className = 'buttonFormStyle'  onClick={this.validationSimulateur} size={"large"} style={{fontWeight:600, paddingLeft:40, paddingRight:40, marginBottom: 100, backgroundColor: '#0093CF', color:'white'}}>Calculer</Button>
              </div>

            }


            <div style = {{color:'white', marginTop:200}}>
              <p>fin</p>
            </div>


          </div>

        </div>

      </div>

      <div style={{ backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundImage: `url(${navBarBackGround})`    }} className="navbar">
            <div className="container1">
              <div className="element1"><a href="/forms" className="active"><img loading="lazy" width="50px" height="50px" src={navBarPicto_analyse} /></a></div>
              <div className="element1"><a href="/simulateur"><img loading="lazy" width="50px" height="50px" src={navBarPicto_calcul_volume_eau} /></a></div>
              <div className="element1"><a href="/"><img loading="lazy" width="50px" height="50px" src={navBarPicto_home} /></a></div>
              <div className="element1"><a href="/historique"><img loading="lazy" width="50px" height="50px" src={navBarPicto_Historique} /></a></div>
              <div className="element1">  <a target="_blank" href="https://www.hydrapro.fr/app/uploads/storelocator0106/wordpress/hydrapro/index.html">  <img loading="lazy" swidth="50px" height="50px" src={navBarPicto_Revendeur} /></a></div>
            </div>
      </div>

      </div>
      )

    };
}





export default SimulateurVolume;
