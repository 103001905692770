import React from 'react';

import axios from '../util/Api'

import {  Table, Tag ,Modal, message , Auxiliary, Row, Col, Form, Input, Button, Select, Space, Slider, Checkbox, InputNumber, Switch} from 'antd';

import {
  BrowserRouter as Router,
  Route,
  Link
} from "react-router-dom";

import {getTypeArticleOption, getMachineId} from "../util/Toolbox";

import './css/containersFormulaire.css';

import iconeHeader from './assets/analyseFormulaire/picto_analyse.svg';
import goutte_eau_header from './assets/analyseFormulaire/goutte_eau_header.svg';

import './css/navBar.css';
import navBarBackGround from './assets/navBar/footer_vague_bleue.svg';
import navBarPicto_analyse from './assets/navBar/picto_analyse.png';
import navBarPicto_calcul_volume_eau from './assets/navBar/picto_calcul_volume_eau.png';
import navBarPicto_home from './assets/navBar/picto_home.png';
import navBarPicto_Historique from './assets/navBar/picto_historique.png';
import navBarPicto_Revendeur from './assets/navBar/picto_revendeur.png';


import {
  EditOutlined
} from '@ant-design/icons';

class AvaForms extends React.Component {

  state = {

    IsFiltreASable : false,
    IsFiltreACartouche : false,
    IsFiltreADiatomee : false,
    IsPocheFiltration : false,

    TempsFiltration : undefined,
    TauxSelAppareil : undefined,
    TemperatureEau : undefined,
    VolumeBassin : '',

    IsTypeDesinfection_ChloreGalet : false,
    IsTypeDesinfection_Brome : false,
    IsTypeDesinfection_ChloreLiquide : false,
    IsTypeDesinfection_Eletrolyseur: false,

    IsTypeEau_MoyenneDure : false,
    IsTypeEau_Dure : false,
    IsTypeEau_TresDur : false,
    IsTypeEau_NeSaisPas : false,

    IsAspect_EauClaire : false,
    IsAspect_EauTrouble : false,
    IsAspect_EauVerte : false,

    loadingButton: false,

    IsAnalysePro : false,
    IsEnCoursDeSaison : false,
    ReferenceAnalyse : '',

    ValeurPH : 7.6,
    ValeurChloreLibre : -1,

    guid_compte: 'fsdfsdsdfsdfsd',


  }


  async componentDidMount() {

    // Récupèration des paramètres dans l'URL si il y en a
    const search  = window.location.search;
    const params  = new URLSearchParams(search);
    const volume  = params.get('volume');

    let guid_compte = getMachineId();

    console.log('guid_compte', guid_compte);

    this.state.guid_compte = guid_compte;




    if(volume != null){
      this.setState({VolumeBassin: volume});
    }

  }

  // Péride de l'analyse
  onChangedebutSaison = (e: CheckboxChangeEvent) => {
  //  this.resetTypeDesinfection();

    console.log(e.target.checked);
    this.setState({IsEnCoursDeSaison:e.target.checked})
  };


  onChangedebutSaison2 = (e: CheckboxChangeEvent) => {
  //  this.resetTypeDesinfection();

    console.log(e.target.checked);
    this.setState({IsEnCoursDeSaison:!e.target.checked})
  };



  // Type Filtration
  onChangeTypeFiltration_Sable = (e: CheckboxChangeEvent) => {
    this.resetTypeFiltration();
    this.setState({IsFiltreASable:e.target.checked})
  };

  onChangeTypeFiltration_Cartouche = (e: CheckboxChangeEvent) => {
    this.resetTypeFiltration();
    this.setState({IsFiltreACartouche:e.target.checked})
  };

  onChangeTypeFiltration_Diatomee = (e: CheckboxChangeEvent) => {
    this.resetTypeFiltration();
    this.setState({IsFiltreADiatomee:e.target.checked})
  };

  onChangeTypeFiltration_Filtration = (e: CheckboxChangeEvent) => {
    this.resetTypeFiltration();
    this.setState({IsPocheFiltration:e.target.checked})
  };



  // Type désinfection
  onChangeTypeDesinfection_ChloreGalet = (e: CheckboxChangeEvent) => {
    this.resetTypeDesinfection();
    this.setState({IsTypeDesinfection_ChloreGalet:e.target.checked})
  };

  onChangeTypeDesinfection_Brome = (e: CheckboxChangeEvent) => {
    this.resetTypeDesinfection();
    this.setState({IsTypeDesinfection_Brome:e.target.checked})
  };

  onChangeTypeDesinfection_ChloreLiquide = (e: CheckboxChangeEvent) => {
    this.resetTypeDesinfection();
    this.setState({IsTypeDesinfection_ChloreLiquide:e.target.checked})
  };

  onChangeTypeDesinfection_Electrolyseur = (e: CheckboxChangeEvent) => {
    this.resetTypeDesinfection();
    this.setState({IsTypeDesinfection_Eletrolyseur:e.target.checked})
  };


  // Type eau
  onChangeTypeEau_MoyenneDure = (e: CheckboxChangeEvent) => {
    this.resetTypeEau();
    this.setState({IsTypeEau_MoyenneDure:e.target.checked, ValeurTH : parseFloat(149) })
  };

  onChangeTypeEau_Dure = (e: CheckboxChangeEvent) => {
    this.resetTypeEau();
    this.setState({IsTypeEau_Dure:e.target.checked, ValeurTH : parseFloat(249) })
  };

  onChangeTypeEau_TresDure = (e: CheckboxChangeEvent) => {
    this.resetTypeEau();
    this.setState({IsTypeEau_TresDur:e.target.checked, ValeurTH : parseFloat(399) })
  };

  onChangeTypeEau_NeSaisPas = (e: CheckboxChangeEvent) => {
    this.resetTypeEau();
    this.setState({IsTypeEau_NeSaisPas:e.target.checked})
  };


  // Aspect de l'Eau
  onChangeAspectEau_Transparent = (e: CheckboxChangeEvent) => {
    this.resetTypeAspectEau();
    this.setState({IsAspect_EauClaire:e.target.checked})
  };

  onChangeAspectEau_Trouble= (e: CheckboxChangeEvent) => {
    this.resetTypeAspectEau();
    this.setState({IsAspect_EauTrouble:e.target.checked})
  };

  onChangeAspectEau_Verte= (e: CheckboxChangeEvent) => {
    this.resetTypeAspectEau();
    this.setState({IsAspect_EauVerte:e.target.checked})
  };


  handleChangeValue(dataType, value) {

    this.setState({[dataType]: value});
}




  // Reset fonction
  resetTypeFiltration(){
    this.setState({IsFiltreASable : false, IsFiltreACartouche : false, IsFiltreADiatomee: false, IsPocheFiltration : false, loadingButton : false  })
  }

  resetTypeDesinfection(){
    this.setState({IsTypeDesinfection_ChloreGalet : false, IsTypeDesinfection_ChloreLiquide : false, IsTypeDesinfection_Brome: false, IsTypeDesinfection_Eletrolyseur : false,  loadingButton : false    })
  }

  resetTypeEau(){
    this.setState({IsTypeEau_MoyenneDure : false, IsTypeEau_Dure : false, IsTypeEau_TresDur: false, IsTypeEau_NeSaisPas : false ,  loadingButton : false   })
  }

  resetTypeAspectEau(){
    this.setState({IsAspect_EauClaire : false, IsAspect_EauTrouble : false, IsAspect_EauVerte: false,  loadingButton : false   })
  }



  // Bouton formulaire
  validationFormulaire = () => {


    if(!this.state.VolumeBassin > 0){
      message.warning("Entrer le volume de votre bassin");
      return;
    }

    if(this.state.IsFiltreASable == false && this.state.IsFiltreACartouche == false && this.state.IsFiltreADiatomee == false && this.state.IsPocheFiltration == false ){
      message.warning("Sélectionner un type de filtration");
      return;
    }

    if(!this.state.TempsFiltration > 0){
      message.warning("Entrer le temps de filtration journalier de votre bassin en heure");
      return;
    }


    if(this.state.IsTypeDesinfection_ChloreGalet == false && this.state.IsTypeDesinfection_Brome == false && this.state.IsTypeDesinfection_ChloreLiquide == false && this.state.IsTypeDesinfection_Eletrolyseur == false ){
      message.warning("Sélectionner un type de désinfection");
      return;
    }
    /*
    if(this.state.IsTypeDesinfection_Eletrolyseur == true && !this.state.TauxSelAppareil > 0  ){
      message.warning("Entrer le taux de sel recommandé par l'appareil (en g/l)");
      return;
    }
    */
    if(this.state.IsTypeEau_MoyenneDure == false && this.state.IsTypeEau_Dure == false && this.state.IsTypeEau_TresDur == false && this.state.IsTypeEau_NeSaisPas == false ){
      message.warning("Compléter l'onglet eau calcaire");
      return;
    }

    if(this.state.IsAspect_EauClaire == false && this.state.IsAspect_EauTrouble == false && this.state.IsAspect_EauVerte == false  ){
      message.warning("Sélectionner l'aspect de l'eau");
      return;
    }

    if(!this.state.TemperatureEau > 0){
      message.warning("Entrer la température de l'eau de votre bassin");
      return;
    }

    if(this.state.IsTypeDesinfection_ChloreGalet == true){

        console.log(this.state.ValeurChloreLibre);

        if(this.state.ValeurChloreLibre >= 0) {

        }else{
          message.warning("Entrer le niveau de chlore de votre bassin");
          return;
        }
    }



    this.setState({ loadingButton: true });

    // analyse
    if(this.state.ValeurChloreLibre <= 0){
      this.state.ValeurChloreLibre = null;
    }



    const messageIndicator = message.loading('Analyse en cours...', 0);

    let formulaire = {
      TemperatureEau : this.state.TemperatureEau,
      TempsFiltration : this.state.TempsFiltration
    }

    console.log(this.state)


      // APPEL API
    axios.post('/analyse/createAnalyse', {analyse : this.state},
    ).then(({data}) => {
      console.log(data);
      console.log(data.result)
      console.log(data.GUIDAnalyse)

      window.location.href= '/analyse?analyse='+ data.GUIDAnalyse;
      this.setState({ loadingButton: false });

      setTimeout(messageIndicator, 10);
    }).catch(function (error) {
      setTimeout(messageIndicator, 10);

      console.log("Error****:", error.message);
      message.error("Erreur lors de l'analyse");
    });



  };


  onChangeSlider = (dataType, value) => {

    this.setState({[dataType]: parseFloat(value)});
  }


  render() {

      return (

        <div className = "wrapper_analyse">

          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>

          <div className = "wrapper_analyse_header">

            <img loading="lazy"  src={iconeHeader} />

            <p className = "wrapper_analyse_header_Title">Analyse</p>

            <div className = "header_image_goutte">
              <img loading="lazy" src={goutte_eau_header} />
            </div>

          </div>

            <div>

              <div className = "containersFormulaire">

                <Row style = {{marginTop:10, marginBottom:-20}}>
                  <Col sm={12} xs={12}>
                  { this.state.IsAnalysePro == true &&
                    <div className = "referenceAnalyse">
                     <Input placeholder='Réf, nom, société ...' value={this.state.ReferenceAnalyse} onChange={(event) => this.setState({ReferenceAnalyse: event.target.value})}/>
                    </div>
                  }

                  </Col>
                  <Col sm={12} xs={12}>
                    <div className = "proAnalyse">
                    <span>Je suis un professionnel</span>
                    <Switch style = {{marginLeft : 5}} checked={Boolean(this.state.IsAnalysePro)} onChange={this.handleChangeValue.bind(this, 'IsAnalysePro')} />
                    </div>
                  </Col>
                </Row>

                <div>

                  <h4>Volume du bassin</h4>


                  <div className="fondFormulaire">
                  <Row gutter = {8}>
                    <Col sm={12} xs={12}>
                      <p>Je connais le volume</p>
                    </Col>
                    <Col sm={10} xs={10} className = "fondFormulaireFont" >
                      <InputNumber min={1} max={10000} style= {{marginTop: 10, width : '100%'}} value={this.state.VolumeBassin} onChange={(event) => this.setState({VolumeBassin: event})}/>
                    </Col>
                    <Col sm={2} xs={2}>
                      <p>m³</p>
                    </Col>
                  </Row>

                  <p style = {{marginTop:20}}>{"Je ne connais pas le volume"}
                   <Link to="/simulateur"><Button style={{marginLeft: 16, backgroundColor: '#1B9BD7', color: 'white', fontWeight : 700}}>Calculer</Button></Link>
                  </p>
                  </div>
                </div>


                <div>
                  <h4>Période de l'analyse</h4>
                  <div className="fondFormulaire">
                  <Row gutter = {8}>
                    <Col sm={12} xs={12}>
                      <Checkbox className = "checkBoxForm" checked = {Boolean(!this.state.IsEnCoursDeSaison) }  onChange={this.onChangedebutSaison2}>en début de saison</Checkbox>
                    </Col>
                    <Col sm={12} xs={12}>
                      <Checkbox className = "checkBoxForm" checked = {Boolean(this.state.IsEnCoursDeSaison)} onChange={this.onChangedebutSaison}>en cours de saison</Checkbox>
                    </Col>
                  </Row>
                </div>
               </div>


                <div>
                  <h4>Type de filtration</h4>
                  <div className="fondFormulaire">
                  <Row gutter = {8}>
                    <Col sm={12} xs={12}>
                      <Checkbox className = "checkBoxForm" checked = {this.state.IsFiltreASable}  onChange={this.onChangeTypeFiltration_Sable}>filtre à sable / verre</Checkbox>
                    </Col>
                    <Col sm={12} xs={12}>
                      <Checkbox className = "checkBoxForm" checked = {this.state.IsFiltreADiatomee} onChange={this.onChangeTypeFiltration_Diatomee}>filtre à diatomée</Checkbox>
                    </Col>
                    <Col sm={12} xs={12}>
                      <Checkbox className = "checkBoxForm" checked = {this.state.IsFiltreACartouche} onChange={this.onChangeTypeFiltration_Cartouche}>filtre à cartouche</Checkbox>
                    </Col>
                    <Col sm={12} xs={12}>
                      <Checkbox className = "checkBoxForm" checked = {this.state.IsPocheFiltration} onChange={this.onChangeTypeFiltration_Filtration}>poche de filtration</Checkbox>
                    </Col>
                  </Row>
                  <p style = {{marginBottom:0}}>Temps de filtration journalier (en heure)</p>
                  <InputNumber value = {this.state.TempsFiltration}  placeholder = "Durée en heure" style={{width: '100%', marginTop : 0, color: 'blue'}} min={1} max={24}  onChange={(event) => this.setState({TempsFiltration: event})} />
                  </div>
                </div>

                <div>
                  <h4>Type de désinfection</h4>
                  <div className="fondFormulaire">
                  <Row gutter = {8}>
                    <Col sm={12} xs={12}>
                      <Checkbox className = "checkBoxForm" checked = {this.state.IsTypeDesinfection_ChloreGalet}  onChange={this.onChangeTypeDesinfection_ChloreGalet}>chlore galet</Checkbox>
                    </Col>
                    <Col sm={12} xs={12}>
                      <Checkbox className = "checkBoxForm" checked = {this.state.IsTypeDesinfection_Brome} onChange={this.onChangeTypeDesinfection_Brome}>brome</Checkbox>
                    </Col>
                    <Col sm={12} xs={12}>
                      <Checkbox className = "checkBoxForm" checked = {this.state.IsTypeDesinfection_ChloreLiquide} onChange={this.onChangeTypeDesinfection_ChloreLiquide}>chlore liquide</Checkbox>
                    </Col>
                    <Col sm={12} xs={12}>
                      <Checkbox className = "checkBoxForm" checked = {this.state.IsTypeDesinfection_Eletrolyseur} onChange={this.onChangeTypeDesinfection_Electrolyseur}>électrolyseur au sel</Checkbox>
                    </Col>
                  </Row>
                  {this.state.IsTypeDesinfection_Eletrolyseur == true &&
                    <InputNumber value = {this.state.TauxSelAppareil}  placeholder = "Taux de sel recommandé par l'appareil (en g/L)" style={{width: '100%', marginTop : 0, color: 'blue'}} min={1} onChange={(event) => this.setState({TauxSelAppareil: event})} />
                  }
                </div>

                </div>

                <div>
                  <h4>Eau calcaire</h4>
                  <div className="fondFormulaire">
                  <Row gutter = {8}>
                    <Col sm={12} xs={12}>
                      <Checkbox className = "checkBoxForm" checked = {this.state.IsTypeEau_MoyenneDure}  onChange={this.onChangeTypeEau_MoyenneDure}>eau moyennement dure <span className = "checkBoxFormSpan">TH inférieur à 200mg/L (20°F)</span></Checkbox>
                    </Col>
                    <Col sm={12} xs={12}>
                      <Checkbox className = "checkBoxForm" checked = {this.state.IsTypeEau_Dure} onChange={this.onChangeTypeEau_Dure}>eau dure <span className = "checkBoxFormSpan">TH supérieur à 200mg/L (20°F)</span></Checkbox>
                    </Col>
                    <Col sm={12} xs={12}>
                      <Checkbox className = "checkBoxForm" checked = {this.state.IsTypeEau_TresDur} onChange={this.onChangeTypeEau_TresDure}>eau très dure <span className = "checkBoxFormSpan">TH supérieur à 400mg/L (40°F)</span></Checkbox>
                    </Col>
                    <Col sm={12} xs={12}>
                      <Checkbox className = "checkBoxForm" checked = {this.state.IsTypeEau_NeSaisPas} onChange={this.onChangeTypeEau_NeSaisPas}>je ne sais pas</Checkbox>
                    </Col>
                  </Row>
                </div>

                </div>

                <div>

                  <h4>Aspect de l'eau</h4>

                  <div className="fondFormulaire">
                  <Row gutter = {8}>
                    <Col sm={24} xs={24}>
                      <Checkbox className = "checkBoxForm" checked = {this.state.IsAspect_EauClaire}  onChange={this.onChangeAspectEau_Transparent}>eau claire transparente</Checkbox>
                    </Col>
                    <Col sm={24} xs={24}>
                      <Checkbox className = "checkBoxForm" checked = {this.state.IsAspect_EauTrouble} onChange={this.onChangeAspectEau_Trouble}>eau trouble</Checkbox>
                    </Col>
                    <Col sm={24} xs={24}>
                      <Checkbox className = "checkBoxForm" checked = {this.state.IsAspect_EauVerte} onChange={this.onChangeAspectEau_Verte}>eau verte</Checkbox>
                    </Col>

                  </Row>
                </div>
                </div>

                <div>
                  <h4>Température de l'eau</h4>

                  <div className="fondFormulaire">
                  <Row gutter = {8}>
                    <Col sm={24} xs={24}>
                        <InputNumber value = {this.state.TemperatureEau}  placeholder = "Température en C°" style={{width: '100%', marginTop : 0, color: 'blue'}} min={1} onChange={(event) => this.setState({TemperatureEau: event})} />
                    </Col>
                  </Row>

                  </div>

                </div>

                <div>

                  <h4>Valeurs mesurées</h4>

                  <div className = "valeur_mesuree">

                    <h5>Valeur du pH</h5>
                    <div className = "valeur_mesuree_item">
                      <Row>
                        <Col className = "valeur_mesuree_left" span={5} style = {{backgroundColor : "#FFCFA7", height:20}} ></Col>
                        <Col span={5} style = {{backgroundColor : "#FFB074",  height:20}}></Col>
                        <Col span={4} style = {{backgroundColor : "#FF9848", height:20}}></Col>
                        <Col span={5} style = {{backgroundColor : "#FF6C1D", height:20}}></Col>
                        <Col className = "valeur_mesuree_right" span={5} style = {{backgroundColor : "#E96C1A", height:20}}></Col>
                      </Row>
                      <Slider onChange={this.onChangeSlider.bind(this, 'ValeurPH')} style = {{marginTop:-16}}  min={6} max={9} defaultValue={this.state.ValeurPH}  step={0.1} trackStyle={{ backgroundColor: 'blue' }} handleStyle={{ borderColor: 'blue' }}  />
                    </div>

                    {
                      (this.state.IsTypeDesinfection_ChloreGalet == true || this.state.IsTypeDesinfection_ChloreLiquide == true || this.state.IsTypeDesinfection_Eletrolyseur == true)   &&
                      (
                        <div>
                          <h5>Taux de chlore libre (mg/L)</h5>
                          <div className = "valeur_mesuree_item">
                            <Row>
                              <Col className = "valeur_mesuree_left" span={5} style = {{backgroundColor : "#DAAEDC", height:20}} ></Col>
                              <Col span={5} style = {{backgroundColor : "#D979DC", height:20}}></Col>
                              <Col span={4} style = {{backgroundColor : "#D051DB", height:20}}></Col>
                              <Col span={5} style = {{backgroundColor : "#BA32B6", height:20}}></Col>
                              <Col className = "valeur_mesuree_right" span={5} style = {{backgroundColor : "#9C1F91", height:20}}></Col>
                            </Row>
                            <Slider onChange={this.onChangeSlider.bind(this, 'ValeurChloreLibre')} style = {{marginTop:-16}}  min={0} max={3} defaultValue={this.state.ValeurChloreLibre}  step={0.1}  />
                          </div>
                        </div>
                      )

                    }

                    {
                      this.state.IsTypeDesinfection_Brome == true &&
                      (
                        <div>
                          <h5>Teneur en brome (mg/L)</h5>
                          <div className = "valeur_mesuree_item">
                            <Row>
                              <Col className = "valeur_mesuree_left" span={5} style = {{backgroundColor : "#DAAEDC", height:20}} ></Col>
                              <Col span={5} style = {{backgroundColor : "#D979DC"}}></Col>
                              <Col span={4} style = {{backgroundColor : "#D051DB"}}></Col>
                              <Col span={5} style = {{backgroundColor : "#BA32B6"}}></Col>
                              <Col className = "valeur_mesuree_right" span={5} style = {{backgroundColor : "#9C1F91"}}></Col>
                            </Row>
                            <Slider onChange={this.onChangeSlider.bind(this, 'ValeurBrome')} style = {{marginTop:-16}}  min={0} max={3} defaultValue={2}  step={0.1}  />
                          </div>
                        </div>

                      )
                    }

                    <h5>Alcanilité ou TAC (mg/L) <span style= {{fontWeight: 400}}> (facultatif) </span></h5>
                    <div className = "valeur_mesuree_item">
                      <Row>
                        <Col className = "valeur_mesuree_left"  span={5} style = {{backgroundColor : "#CFE9BA", height:20}} ></Col>
                        <Col span={5} style = {{backgroundColor : "#8BE1BA"}}></Col>
                        <Col span={4} style = {{backgroundColor : "#8BBBE3"}}></Col>
                        <Col span={5} style = {{backgroundColor : "#65A5E4"}}></Col>
                        <Col className = "valeur_mesuree_right" span={5} style = {{backgroundColor : "#3981D5"}}></Col>
                      </Row>
                      <Slider onChange={this.onChangeSlider.bind(this, 'ValeurTAC')} style = {{marginTop:-16}}  min={-1} max={300} defaultValue={-1}  step={1}  />
                    </div>

                    <h5>Dureté totale ou TH (mg/L) <span style= {{fontWeight: 400}}> (facultatif) </span></h5>
                    <div className = "valeur_mesuree_item">
                      <Row>
                        <Col className = "valeur_mesuree_left"  span={5} style = {{backgroundColor : "#8AB52B", height:20}} ></Col>
                        <Col span={5} style = {{backgroundColor : "#B3C55E"}}></Col>
                        <Col span={4} style = {{backgroundColor : "#D3DE74"}}></Col>
                        <Col span={5} style = {{backgroundColor : "#E4E89F"}}></Col>
                        <Col className = "valeur_mesuree_right" span={5} style = {{backgroundColor : "#EBEBC6"}}></Col>
                      </Row>
                      <Slider onChange={this.onChangeSlider.bind(this, 'ValeurTH')} value = {this.state.ValeurTH} style = {{marginTop:-16}}  min={-1} max={500} defaultValue={-1}  step={1}  />
                    </div>

                    <h5>Stabilisant (mg/L) <span style= {{fontWeight: 400}}> (facultatif) </span></h5>
                    <div className = "valeur_mesuree_item">
                      <Row>
                        <Col className = "valeur_mesuree_left"  span={5} style = {{backgroundColor : "#FFDED6", height:20}} ></Col>
                        <Col span={5} style = {{backgroundColor : "#F9C5B6"}}></Col>
                        <Col span={4} style = {{backgroundColor : "#FBACAD"}}></Col>
                        <Col span={5} style = {{backgroundColor : "#FD6F70"}}></Col>
                        <Col className = "valeur_mesuree_right" span={5} style = {{backgroundColor : "#D65B5C"}}></Col>
                      </Row>
                      <Slider onChange={this.onChangeSlider.bind(this, 'ValeurStabilisant')} style = {{marginTop:-16}}  min={-1} max={150} defaultValue={-1}  step={1}  />
                    </div>

                    <h5>Phosphate en ppb <span style= {{fontWeight: 400}}> (facultatif) </span></h5>
                    <div className = "valeur_mesuree_item">
                      <Row>
                        <Col className = "valeur_mesuree_left"  span={5} style = {{backgroundColor : "#FFF8D4", height:20}} ></Col>
                        <Col span={5} style = {{backgroundColor : "#FAE5BB"}}></Col>
                        <Col span={4} style = {{backgroundColor : "#DFCABB"}}></Col>
                        <Col span={5} style = {{backgroundColor : "#CBB983"}}></Col>
                        <Col className = "valeur_mesuree_right" span={5} style = {{backgroundColor : "#A49A79"}}></Col>
                      </Row>
                      <Slider onChange={this.onChangeSlider.bind(this, 'ValeurPhosphate')} style = {{marginTop:-16}}  min={-1} max={2500} defaultValue={-1}  step={1}  />
                    </div>

                  </div>
                </div>

                <div className = "buttonForm">
                  <Button className = 'buttonFormStyle'  onClick={this.validationFormulaire} loading = {this.state.loadingButton} size={"large"} style={{fontWeight:600, paddingLeft:40, paddingRight:40, marginBottom: 100, backgroundColor: '#0093CF', color:'white'}}>Analyser</Button>
                </div>

              </div>

            </div>

            <div style={{ backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundImage: `url(${navBarBackGround})`    }} className="navbar">
                  <div className="container1">
                    <div className="element1"><a href="/forms" className="active"><img loading="lazy" width="50px" height="50px" src={navBarPicto_analyse} /></a></div>
                    <div className="element1"><a href="/simulateur"><img loading="lazy" width="50px" height="50px" src={navBarPicto_calcul_volume_eau} /></a></div>
                    <div className="element1"><a href="/"><img loading="lazy" width="50px" height="50px" src={navBarPicto_home} /></a></div>
                    <div className="element1"><a href="/historique"><img loading="lazy" width="50px" height="50px" src={navBarPicto_Historique} /></a></div>
                    <div className="element1">  <a target="_blank" href="https://www.hydrapro.fr/app/uploads/storelocator0106/wordpress/hydrapro/index.html">  <img loading="lazy" width="50px" height="50px" src={navBarPicto_Revendeur} /></a></div>
                  </div>
            </div>

        </div>





      )

    };
}

/*
{ this.state.IsTypeDesinfection_Eletrolyseur == true &&
  (
    <div>
      <h5>Taux de sel (g/L)</h5>
      <div className = "valeur_mesuree_item">
        <Row>
          <Col className = "valeur_mesuree_left"  span={5} style = {{backgroundColor : "#3D320D", height:20}} ></Col>
          <Col span={5} style = {{backgroundColor : "#544513"}}></Col>
          <Col span={4} style = {{backgroundColor : "#706233"}}></Col>
          <Col span={5} style = {{backgroundColor : "#A69870"}}></Col>
          <Col className = "valeur_mesuree_right" span={5} style = {{backgroundColor : "#D1C18E"}}></Col>
        </Row>
        <Slider onChange={this.onChangeSlider.bind(this, 'ValeurTauxSel')} style = {{marginTop:-16}}  min={-1} max={6} defaultValue={-1}  step={0.1}  />
      </div>
    </div>
  )
}

*/
//url(require("./assets/navBar/footer_vague_bleue.svg"))

export default AvaForms;
