import React from 'react';

import { message , Row, Col, Button,Steps, Progress} from 'antd';

import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes
} from "react-router-dom";

import axios from '../util/Api'

import {getTypeArticleOption, addAlpha, parseJSONItem, cleanJSONContent} from "../util/Toolbox";

import iconeHeader from './assets/analyseTraitement/picto_traitement.svg';
import goutte_eau_header from './assets/analyseFormulaire/goutte_eau_header.svg';

import ALGICIDE_200_1L from './assets/analyseTraitementFamille/Articles/ALGICIDE_200_1L.svg';
import ACTIVATEUR_BROME_5KG from './assets/analyseTraitementFamille/Articles/ACTIVATEUR_BROME_5KG.svg';
import ANTI_CALCAIRE_5L from './assets/analyseTraitementFamille/Articles/ANTI_CALCAIRE_5L.svg';
import BROME_5KG from './assets/analyseTraitementFamille/Articles/BROME_5KG.svg';
import CARTON_FLOCULANT_CHAUSSETTE from './assets/analyseTraitementFamille/Articles/CARTON_FLOCULANT_CHAUSSETTE.svg';
import CHLORE_CHOC_GRANULE_5KG from './assets/analyseTraitementFamille/Articles/CHLORE_CHOC_GRANULE_5KG.svg';
import CHLORE_CHOC_PASTILLES_5KG from './assets/analyseTraitementFamille/Articles/CHLORE_CHOC_PASTILLES_5KG.svg';
import CHLORE_LENT_250G_5KG from './assets/analyseTraitementFamille/Articles/CHLORE_LENT_250G_5KG.svg';
import CHLORE_MULTIFONCTIONS_250G_5KG from './assets/analyseTraitementFamille/Articles/CHLORE_MULTIFONCTIONS_250G_5KG.svg';
import CHLORE_MULTIFONCTIONS_SANS_CUIVRE_250G_5KG from './assets/analyseTraitementFamille/Articles/CHLORE_MULTIFONCTIONS_SANS_CUIVRE_250G_5KG.svg';
import CHLORIT_5KG from './assets/analyseTraitementFamille/Articles/CHLORIT_5KG.svg';
import FLOCULANT_5L from './assets/analyseTraitementFamille/Articles/FLOCULANT_5L.svg';
import O_ACTIVE_5L from './assets/analyseTraitementFamille/Articles/O_ACTIVE_5L.svg';
import PH_MOINS_LIQUIDE from './assets/analyseTraitementFamille/Articles/PH_MOINS_LIQUIDE.svg';
import PH_PLUS_5KG from './assets/analyseTraitementFamille/Articles/PH_PLUS_5KG.svg';
import STICKS_DE_CHLORIT_5KG from './assets/analyseTraitementFamille/Articles/STICKS_DE_CHLORIT_5KG.svg';
import TAC_MOINS_1KG from './assets/analyseTraitementFamille/Articles/TAC_MOINS_1KG.svg';
import TAC_PLUS_5KG from './assets/analyseTraitementFamille/Articles/TAC_PLUS_5KG.svg';
import TH_PLUS_5KG from './assets/analyseTraitementFamille/Articles/TH_PLUS_5KG.svg';
import PH_MOINS_5KG from './assets/analyseTraitementFamille/Articles/PH_MOINS_5KG.svg';
import CLARIPUR from './assets/analyseTraitementFamille/Articles/CLARIPUR.svg';
import CHLORESTAB_5KG from './assets/analyseTraitementFamille/Articles/CHLORESTAB_5KG.svg';
import PH_PLUS_5L from './assets/analyseTraitementFamille/Articles/PH_PLUS_5L.svg';
import ANTI_PHOSPHATES_1L from './assets/analyseTraitementFamille/Articles/ANTI_PHOSPHATES_1L.svg';
import PH_MOINS_15 from './assets/analyseTraitementFamille/Articles/PH_MOINS_15.svg';





import './css/navBar.css';
import navBarBackGround from './assets/navBar/footer_vague_bleue.svg';
import navBarPicto_analyse from './assets/navBar/picto_analyse.png';
import navBarPicto_calcul_volume_eau from './assets/navBar/picto_calcul_volume_eau.png';
import navBarPicto_home from './assets/navBar/picto_home.png';
import navBarPicto_Historique from './assets/navBar/picto_historique.png';
import navBarPicto_Revendeur from './assets/navBar/picto_revendeur.png';

import GALETS from './assets/analyseTraitementFamille/GALETS.svg';
import GRANULES from './assets/analyseTraitementFamille/GRANULES.svg';
import LIQUIDE from './assets/analyseTraitementFamille/LIQUIDE.svg';
import PASTILLES from './assets/analyseTraitementFamille/PASTILLES.svg';
import picto_POUDRE from './assets/analyseTraitementFamille/picto_POUDRE.svg';
import POUDRE from './assets/analyseTraitementFamille/POUDRE.svg';
import STICK_CHAUSSETTE from './assets/analyseTraitementFamille/STICK_CHAUSSETTE.svg';

import ALGICIDE from './assets/analyseTraitement/ALGICIDE.svg';
import ANTI_CALCAIRE from './assets/analyseTraitement/ANTI_CALCAIRE.svg';
import BROME from './assets/analyseTraitement/BROME.svg';
import CHLORE_CHOC from './assets/analyseTraitement/CHLORE_CHOC.svg';
import CHLORE from './assets/analyseTraitement/CHLORE.svg';
import CHLORIT from './assets/analyseTraitement/CHLORIT.svg';
import CLARIFIANT from './assets/analyseTraitement/CLARIFIANT.svg';
import O_ACTIVE from './assets/analyseTraitement/O_ACTIVE.svg';
import PH_MOINS from './assets/analyseTraitement/PH_MOINS.svg';
import PH_PLUS from './assets/analyseTraitement/PH_PLUS.svg';
import TAC_MOINS from './assets/analyseTraitement/TAC_MOINS.svg';
import TAC_PLUS from './assets/analyseTraitement/TAC_PLUS.svg';
import TH from './assets/analyseTraitement/TH.svg';

import {getColorWithState} from "../util/Toolbox";

import './css/analyseTraitementFamille.css';

import {
  EditOutlined,
  SearchOutlined,
  CloudDownloadOutlined,
  ShareAltOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
  CaretLeftOutlined,
  UserOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined

} from '@ant-design/icons';



 class AnalyseTraitementFamille extends React.Component {

  constructor(props) {

    super(props);

    this.state = {

      traitement_LIST : [],
      GUID_Analyse : '',
      IDFamille : '',
      ColorFamille : '',
      currentFamille : null,
      ListArticle : [],
      articleIndexCurrent : 0,
      ListModeEmploiCurrent : [],
      IndexCurrentEtape : 0

    }

  }


  async componentDidMount() {

    const search  = window.location.search;
    const params  = new URLSearchParams(search);
    const IDFamille   = params.get('IDFamille');
    this.loadProduits(IDFamille);

  }


  loadProduits(IDFamille){

    axios.get(`analyse/${IDFamille}/produits`,
    ).then(({data}) => {
      //console.log(data);

      if(data.success == 'success'){

          let currentFamille      = data.result.traitement_LIST.find(o => o.IDFamille == IDFamille);
          let currentFamilleJSON  = cleanJSONContent(currentFamille.ListArticle);

          console.log(currentFamilleJSON);
          currentFamille.ListArticle = currentFamilleJSON;

          this.state.ListArticle = currentFamille.ListArticle;

          this.setState({ IndexCurrentEtape : data.result.IndexCurrentEtape , ListModeEmploiCurrent: currentFamille.ListArticle.length > 0 ? currentFamille.ListArticle[0].ListModeEmploi : [], ListArticle : currentFamille.ListArticle, currentFamille : currentFamille, ColorFamille: currentFamille.CouleurFamille, IDFamille : IDFamille,traitement_LIST : data.result.traitement_LIST });
      }

    }).catch(function (error) {
      console.log("Error****:", error.message);
    });

  }


  retourVersResumeAnalyse = () =>{

    window.location.href= '/analyseProduitsAll';
  }


  retourVersFicheProduit = () => {

    window.open( this.state.ListArticle.length > 0 ? this.state.ListArticle[this.state.articleIndexCurrent].PathFicheProduit : "", '_blank');
  }

  previousClick = () => {

    let articleIndexCurrent = this.state.articleIndexCurrent;

    if(articleIndexCurrent > 0){
      articleIndexCurrent = articleIndexCurrent - 1;
      this.setState({articleIndexCurrent : articleIndexCurrent, ListModeEmploiCurrent : this.state.currentFamille.ListArticle[articleIndexCurrent].ListModeEmploi})
    }

  }


  nextClick = () => {

    let articleIndexCurrent = this.state.articleIndexCurrent;

    if(articleIndexCurrent < this.state.ListArticle.length -1){
      articleIndexCurrent = articleIndexCurrent + 1;
      this.setState({articleIndexCurrent : articleIndexCurrent, ListModeEmploiCurrent : this.state.currentFamille.ListArticle[articleIndexCurrent].ListModeEmploi})
    }
  }



  renderImgTypeArticle(article) {


    if(article == null) {
      return null;
    }

    if(article.IsGalet == 1){
      return GALETS;
    }

    if(article.IsLiquide == 1){
      return LIQUIDE;
    }

    if(article.IsGranule == 1){
      return GRANULES;
    }

    if(article.IsPoudre == 1){
      return POUDRE;
    }

    if(article.IsPastille == 1){
      return PASTILLES;
    }

    if(article.IsStick == 1){
      return STICK_CHAUSSETTE;
    }

  }


  renderImageArticle(article){

    if(article == null){
      return null
    }


    if(article.CONST_Image == 'ALGICIDE_200_1L') {
      return ALGICIDE_200_1L
    }

    if(article.CONST_Image == 'ACTIVATEUR_BROME_5KG') {
      return ACTIVATEUR_BROME_5KG
    }

    if(article.CONST_Image == 'ANTI_CALCAIRE_5L') {
      return ANTI_CALCAIRE_5L
    }

    if(article.CONST_Image == 'BROME_5KG') {
      return BROME_5KG
    }

    if(article.CONST_Image == 'CARTON_FLOCULANT_CHAUSSETTE') {
      return CARTON_FLOCULANT_CHAUSSETTE
    }

    if(article.CONST_Image == 'CHLORE_CHOC_GRANULE_5KG') {
      return CHLORE_CHOC_GRANULE_5KG
    }

    if(article.CONST_Image == 'CHLORE_CHOC_PASTILLES_5KG') {
      return CHLORE_CHOC_PASTILLES_5KG
    }

    if(article.CONST_Image == 'CHLORE_LENT_250G_5KG') {
      return CHLORE_LENT_250G_5KG
    }

    if(article.CONST_Image == 'CHLORE_MULTIFONCTIONS_250G_5KG') {
      return CHLORE_MULTIFONCTIONS_250G_5KG
    }

    if(article.CONST_Image == 'CHLORE_MULTIFONCTIONS_SANS_CUIVRE_250G_5KG') {
      return CHLORE_MULTIFONCTIONS_SANS_CUIVRE_250G_5KG
    }

    if(article.CONST_Image == 'CHLORIT_5KG') {
      return CHLORIT_5KG
    }

    if(article.CONST_Image == 'FLOCULANT_5L') {
      return FLOCULANT_5L
    }

    if(article.CONST_Image == 'O_ACTIVE_5L') {
      return O_ACTIVE_5L
    }

    if(article.CONST_Image == 'PH_MOINS_LIQUIDE') {
      return PH_MOINS_LIQUIDE
    }

    if(article.CONST_Image == 'PH_PLUS_5KG') {
      return PH_PLUS_5KG
    }

    if(article.CONST_Image == 'STICKS_DE_CHLORIT_5KG') {
      return STICKS_DE_CHLORIT_5KG
    }

    if(article.CONST_Image == 'TAC_MOINS_1KG') {
      return TAC_MOINS_1KG
    }

    if(article.CONST_Image == 'TAC_PLUS_5KG') {
      return TAC_PLUS_5KG
    }

    if(article.CONST_Image == 'TH_PLUS_5KG') {
      return TH_PLUS_5KG
    }

    if(article.CONST_Image == 'PH_PLUS_5L') {
      return PH_PLUS_5L
    }

    if(article.CONST_Image == 'CLARIPUR') {
      return CLARIPUR
    }

    if(article.CONST_Image == 'CHLORESTAB_5KG') {
      return CHLORESTAB_5KG
    }

    if(article.CONST_Image == 'ANTI_PHOSPHATES_1L') {
      return ANTI_PHOSPHATES_1L
    }

    if(article.CONST_Image == 'PH_MOINS_5KG') {
      return PH_MOINS_5KG
    }

    if(article.CONST_Image == 'PH_MOINS_15') {
      return PH_MOINS_15
    }




    return null;

  }


  render() {

      return (

        <div className = "wrapper_analyse" style = {{background: this.state.ColorFamille }}>

          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>

          <div className = "wrapper_analyse_header">

            <img src={iconeHeader} />

            <p className = "wrapper_analyse_header_Title">Traitement</p>

            <div className = "header_image_goutte">
              <img src={goutte_eau_header} />
            </div>

          </div>

          <div className= "bassin_wrapper">

            <Row style = {{marginTop:20}}>

              <Col span = {12}>
                <div className = "traitementsValeurLeftTitre">
                  <span style = {{color : this.state.ColorFamille}}>{  this.state.ListArticle.length > 0 ? this.state.ListArticle[this.state.articleIndexCurrent].LibelleArticle : ""} </span>
                </div>
                <div style = {{background: this.state.ColorFamille}} className = "traitementsValeurLeft">

                  <span>
                  {<img width="50" height="50" src = {this.renderImgTypeArticle(this.state.ListArticle.length > 0 ? this.state.ListArticle[this.state.articleIndexCurrent] : null)}/>}
                  </span>
                </div>
              </Col>

              <Col span = {12}>
                <div style = {{background: this.state.ColorFamille}} className = "traitementsValeurRight">
                  <p style = {{marginLeft : 5, marginRight : 5 , fontSize : 13}} >{this.state.ListArticle.length > 0 ? this.state.ListArticle[this.state.articleIndexCurrent].Dosage : ""}</p>
                </div>
              </Col>

            </Row>

            <div className = "carousselTraitement" style = {{color: this.state.ColorFamille}}>

             <span onClick={this.previousClick}><CaretLeftOutlined className = "carousselTraitementLeft" style = {{fontSize:30, color: this.state.articleIndexCurrent > 0 ? this.state.ColorFamille :  'grey'}}/>
             </span>

            <img src = {this.renderImageArticle( this.state.ListArticle.length > 0 ? this.state.ListArticle[this.state.articleIndexCurrent] : null )}/>

            <span onClick={this.nextClick}> <CaretRightOutlined className= "carousselTraitementRight" style = {{fontSize:30, color: this.state.articleIndexCurrent < this.state.ListArticle.length -1 ? this.state.ColorFamille :  'grey'}} />
            </span>

          </div>

          <Row style = {{marginTop: 20}} gutter={[1, 2]}>

          {
            this.state.ListModeEmploiCurrent.map((item) => (
              <Col sm={24} xs={24}>
                  <div className= "traitementModeEmploi_cell" style={{background: addAlpha(this.state.ColorFamille, 0.13)}}>
                    <p className = "titreModeEmploi">{item.TitreEtape}</p>
                    <p style = {{marginTop : -16, opacity : 1}} className = "descriptionModeEmploi" >{item.DesignationEtape}</p>
                  </div>
              </Col>
            ))
          }

          </Row>

          <div style = {{marginTop:20, marginBottom:20, textAlign : 'center'} }>
              <Button className = 'buttonFormStyle' onClick = {this.retourVersFicheProduit}  size={"large"} style={{width : "250px", fontWeight:600, paddingLeft:40, paddingRight:40, marginBottom: 16, backgroundColor: '#0093CF', color:'white'}}>Fiche produit</Button>
          </div>


          <div style = {{marginTop:20, marginBottom:100, textAlign : 'center'} }>
              <Button className = 'buttonFormStyle' onClick = {this.retourVersResumeAnalyse}  size={"large"} style={{width : "250px", fontWeight:600, paddingLeft:40, paddingRight:40, marginBottom: 16, backgroundColor: '#0093CF', color:'white'}}>Retour aux produits</Button>
          </div>


          <div style = {{display: 'none', marginTop:40, marginBottom:100, textAlign : 'center'} }>
            Revenir vers les traitements
          </div>

        </div>

        <div style={{ backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundImage: `url(${navBarBackGround})`    }} className="navbar">
              <div className="container1">
                <div className="element1"><a href="/forms" className="active"><img width="50px" height="50px" src={navBarPicto_analyse} /></a></div>
                <div className="element1"><a href="/simulateur"><img width="50px" height="50px" src={navBarPicto_calcul_volume_eau} /></a></div>
                <div className="element1"><a href="/"><img width="50px" height="50px" src={navBarPicto_home} /></a></div>
                <div className="element1"><a href="/historique"><img width="50px" height="50px" src={navBarPicto_Historique} /></a></div>
                <div className="element1"><a target="_blank" href="https://www.hydrapro.fr/app/uploads/storelocator0106/wordpress/hydrapro/index.html">  <img width="50px" height="50px" src={navBarPicto_Revendeur} /></a></div>
              </div>
        </div>

      </div>

      )

    };
}



export default AnalyseTraitementFamille;
