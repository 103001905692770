import React from "react";
import {Select} from "antd";

const Option = Select.Option;



export function getBaseURL(){

  let baseURL = process.env.REACT_APP_URL;

  if(baseURL == undefined) {
    baseURL = 'http://127.0.0.1:9070';
  }

  return baseURL;

}



export function getMachineId() {

    let machineId = localStorage.getItem('MachineIdUnique');

    if (!machineId) {
        machineId = crypto.randomUUID();
        localStorage.setItem('MachineIdUnique', machineId);
    }

    return machineId;
}




export function cleanJSONContent(data) {
  if(data === null || data === "" || data === undefined) {
    return [];
  }

  return JSON.parse(JSON.stringify(eval("(" + data + ")")))
}

export function parseJSONItem(list, itemName) {
  for(var i in list) {
    list[i][itemName] = cleanJSONContent(list[i][itemName]);
  }
  return list;
}


export function addAlpha(color, opacity) {
    // coerce values so ti is between 0 and 1.
    var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
}



export function getTypeArticleOption() {
  var list = [];
  list.push(<Option key={1} value={1}>Poudre</Option>);
  list.push(<Option key={2} value={2}>Liquide</Option>);
  return list;
}


export function getFrDateFormat(date) {
  if(date === undefined || date === null)
    return null;
  return formatDate(new Date(date),'dd/MM/yyyy');
}



export function formatDate(x, y) {
  if(x.getTime() === 0)
    return null;
  if(x.getFullYear() === 1970)
    return '-'

  var z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds()
  };
  y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
      return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-2)
  });

  return y.replace(/(y+)/g, function(v) {
      return x.getFullYear().toString().slice(-v.length)
  });
}



export function getColorWithState(state) {
  let color = '';

  switch (state) {

    case 'BON':
      color = 'green'
      break;
    case 'MOYEN':
      color = 'orange'
      break;
    case 'MAUVAIS':
      color = 'red'
      break;
    default:
  }

  return color;

}
