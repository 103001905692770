import axios from 'axios';

var axiosConfig = {
  baseURL: process.env.REACT_APP_URL,
  headers: {
    'Content-Type': 'application/json',
  }
}

if(axiosConfig.baseURL === undefined){
  axiosConfig.baseURL = 'http://127.0.0.1:9070'
}

export default axios.create(axiosConfig);
