import React from 'react';

const AvaForm = () => {
  return (
    <div>An ava form</div>
  )

  ;
};

export default AvaForm;
