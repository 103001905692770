import React from 'react';

import {  Table, Tag ,Modal, message , Auxiliary, Row, Col, Form, Input, Button, Select, Popconfirm} from 'antd';

import {getTypeArticleOption, getFrDateFormat, getMachineId} from "../util/Toolbox";

import './css/historique.css';

import iconeHeader from './assets/historique/picto_historique.svg';
import goutte_eau_header from './assets/analyseFormulaire/goutte_eau_header.svg';


import './css/navBar.css';
import navBarBackGround from './assets/navBar/footer_vague_bleue.svg';
import navBarPicto_analyse from './assets/navBar/picto_analyse.png';
import navBarPicto_calcul_volume_eau from './assets/navBar/picto_calcul_volume_eau.png';
import navBarPicto_home from './assets/navBar/picto_home.png';
import navBarPicto_Historique from './assets/navBar/picto_historique.png';
import navBarPicto_Revendeur from './assets/navBar/picto_revendeur.png';


import axios from '../util/Api'

import {
  EditOutlined,
  SearchOutlined,
  CloudDownloadOutlined,
  ShareAltOutlined,
  DeleteOutlined

} from '@ant-design/icons';

class Historique extends React.Component {

  state = {
    historique_LIST : [],
    search : '',
    guid_compte: 'fsdfsdsdfsdfsd',
    editionKey : '',
    inputValue : '',
    stateConfirmeDelete : false,
    analyseCurrent : null,
    isLoading : false,

  }

  handleSearch = (pe_search) => {

    console.log('handleSearch ' + pe_search)
    this.state.search = pe_search;
    this.loadHistorique();
  };


  async componentDidMount() {

    let guid_compte = getMachineId();

    console.log('guid_compte', guid_compte);

    this.state.guid_compte = guid_compte;
    this.loadHistorique();
}


  loadHistorique(){

    console.log('loadHistorique')


    let search = '-1';

    if(this.state.search != ''){
      search = this.state.search;
    }

    this.setState({isLoading : true})
    axios.get(`analyse/${this.state.guid_compte}/${search}/historique`,
    ).then(({data}) => {
      console.log(data);

      if(data.success == 'success'){
        this.setState({historique_LIST : data.result, isLoading : false});
      }

    }).catch(function (error) {
      console.log("Error****:", error.message);
      this.setState({isLoading : false})
    });

  }


  consulterAnalyse(item){

    window.location.href= '/analyse?analyse='+ item.GUID_Analyse;
  }


  telechargerAnalyse(item){
    message.info("Cette fonctionalité pas encore disponible")
    return
    console.log(item);
  }


  partagerAnalyse(item){
    message.info("Cette fonctionalité pas encore disponible")
    return
    console.log(item);
  }

  supprimerAnalyse(item){
    console.log(item);
    this.setState({stateConfirmeDelete : true, analyseCurrent : item})

  }

  confirmDeleteAnalyse(){
    console.log('confirmDeleteAnalyse');
    console.log(this.state.analyseCurrent)

    axios.get(`analyse/${this.state.analyseCurrent.GUID_Analyse}/deleteAnalyse`,
    ).then(({data}) => {

      console.log(data);
      this.loadHistorique();

    }).catch(function (error) {
      console.log("Error****:", error.message);
    });


    this.cancelDeleteAnalyse();

  }

  cancelDeleteAnalyse(){
    console.log('confirmDeleteAnalyse');
    this.setState({stateConfirmeDelete : false})
  }

  editRow(item){

    this.setState({editionKey : item.GUID_Analyse , inputValue : item.ReferenceAnalyse});
  }


  handleChange(inputValue) {
    this.setState({inputValue});
  }

  saveItem() {

    console.log('saveItem')

    let GUID_Analyse       = this.state.editionKey;
    let historique_LIST    = [...this.state.historique_LIST] ;
    let ReferenceAnalyse   = this.state.inputValue ;

    console.log(ReferenceAnalyse);


    for (var i = 0; i < historique_LIST.length; i++) {
      if(historique_LIST[i].GUID_Analyse == GUID_Analyse){
        historique_LIST[i].ReferenceAnalyse = ReferenceAnalyse;
      }
    }

    axios.post('/analyse/updateHistorique', {historique : {GUIDAnalyse : this.state.editionKey, LibelleAnalyse : this.state.inputValue } },
    ).then(({data}) => {
      this.setState({editionKey : '', inputValue : '', historique_LIST : historique_LIST })
    }).catch(function (error) {
      console.log("Error****:", error.message);
      message.error("Erreur lors de l'analyse");
    });

  }

  render() {

      const columns = [

        {
            title: 'Référence',
            dataIndex: 'ReferenceAnalyse',
            width: '30%',
            sorter: (a, b) => a.ReferenceAnalyse.localeCompare(b.ReferenceAnalyse),
            render: (text, record) => (
              <span  style = {{color : record.ReferenceAnalyse == ''? "grey" : ""   }} onClick={() => this.editRow(record) }>

                {this.state.editionKey === record.GUID_Analyse ?
                <Input
                  defaultValue= {this.state.inputValue}
                  allowClear={true}
                  autoFocus
                  onChange={(event) => this.handleChange(event.target.value)}
                  onPressEnter={() => this.saveItem()}
                />
                  : record.ReferenceAnalyse == '' ? "Cliquer pour éditer" : record.ReferenceAnalyse}

              </span>
            )
        },

        {
            title: 'Date',
            dataIndex: 'LibelleHistorique',
            render: (text, record) => (
              <span>
                {getFrDateFormat(record.DateAnalyse)}
              </span>
            ),
            sorter: (a, b) => a.DateAnalyse.localeCompare(b.DateAnalyse),
            width: '20%'
        },

        {
            title: '',
            dataIndex: 'Consulter',
            width: '5%',
            render: (text, record) => (
              <span onClick={() => this.consulterAnalyse(record)}>
                <SearchOutlined style={{color: '#97C11D', fontSize: '20px', cursor: 'pointer'}} type="edit"/>
              </span>
            )
        },



        {
            title: '',
            dataIndex: 'Historique',
            width: '5%',
            render: (text, record) => (
              <span onClick={() => this.supprimerAnalyse(record)}>
                <DeleteOutlined style={{color: '#707070', fontSize: '20px', cursor: 'pointer'}} type="edit"/>
              </span>
            )
        }




    ];

    /*
    {
        title: '',
        dataIndex: 'Telecharger',
        width: '5%',
        render: (text, record) => (
          <span onClick={() => this.telechargerAnalyse(record)}>
            <CloudDownloadOutlined style={{color: '#97C11D', fontSize: '20px', cursor: 'pointer'}} type="edit"/>
          </span>
        )
    },

    {
        title: '',
        dataIndex: 'Historique',
        width: '5%',
        render: (text, record) => (
          <span onClick={() => this.partagerAnalyse(record)}>
            <ShareAltOutlined style={{color: '#97C11D', fontSize: '20px', cursor: 'pointer'}} type="edit"/>
          </span>
        )
    },
    */
      return (

        <div>

          <div className = "wrapper_historique">

            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>

            <div className = "wrapper_historique_header">

              <img loading="lazy"  src={iconeHeader} />

              <p className = "wrapper_analyse_header_Title">Historique</p>

              <div className = "header_image_goutte">
                <img loading="lazy"  src={goutte_eau_header} />
              </div>

            </div>

            <div className = "containersHistorique">

            <Input onChange={(event) => this.handleSearch(event.target.value)}  style = {{marginTop:12}} placeholder="Rechercher par data, nom, etc.." />

            {
              this.state.stateConfirmeDelete == true &&

              <Modal
                title="Suppression analyse"
                open={this.state.stateConfirmeDelete}
                onOk={this.confirmDeleteAnalyse.bind(this)}
                onCancel={this.cancelDeleteAnalyse.bind(this)}
                okText= "Supprimer"
                cancelText="Annuler"
              >
                <p>Supprimer l'analyse ?</p>
              </Modal>

            }

            <Table
              className = "historique_table"
              columns={columns}
              size="small"
              dataSource={this.state.historique_LIST}
              pagination={false}
              bordered = {false}
              sticky={true}
              loading = {this.state.isLoading}
              scroll={{y:"calc(100vh - 300px)" }}
            />

            </div>

          </div>

          <div style={{ backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundImage: `url(${navBarBackGround})`    }} className="navbar">
                <div className="container1">
                  <div className="element1"><a href="/forms" className="active"><img loading="lazy"  width="50px" height="50px" src={navBarPicto_analyse} /></a></div>
                  <div className="element1"><a href="/simulateur"><img loading="lazy"  width="50px" height="50px" src={navBarPicto_calcul_volume_eau} /></a></div>
                  <div className="element1"><a href="/"><img loading="lazy"  width="50px" height="50px" src={navBarPicto_home} /></a></div>
                  <div className="element1"><a href="/historique"><img loading="lazy"  width="50px" height="50px" src={navBarPicto_Historique} /></a></div>
                  <div className="element1">  <a target="_blank" href="https://www.hydrapro.fr/app/uploads/storelocator0106/wordpress/hydrapro/index.html">  <img loading="lazy"  width="50px" height="50px" src={navBarPicto_Revendeur} /></a></div>
                </div>
          </div>

        </div>

      )

    };
}



export default Historique;
