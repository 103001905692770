import React from 'react';

import {  Table, Tag ,Modal, message , Auxiliary, Row, Col, Form, Input, Button, Select, Collapse} from 'antd';

import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
  useHistory,
  Navigate
} from "react-router-dom";

import axios from '../util/Api'

import {getTypeArticleOption, getBaseURL} from "../util/Toolbox";

import picto_analyse_bleu from './assets/analyseResume/picto_analyse_bleu.svg';
import goutte_orange      from './assets/analyseResume/goutte_orange.svg';
import goutte_verte       from './assets/analyseResume/goutte_verte.svg';
import goutte_rouge       from './assets/analyseResume/goutte_rouge.svg';

import iconeHeader from './assets/analyseFormulaire/picto_analyse.svg';
import goutte_eau_header from './assets/analyseFormulaire/goutte_eau_header.svg';

import './css/navBar.css';
import navBarBackGround from './assets/navBar/footer_vague_bleue.svg';
import navBarPicto_analyse from './assets/navBar/picto_analyse.png';
import navBarPicto_calcul_volume_eau from './assets/navBar/picto_calcul_volume_eau.png';
import navBarPicto_home from './assets/navBar/picto_home.png';
import navBarPicto_Historique from './assets/navBar/picto_historique.png';
import navBarPicto_Revendeur from './assets/navBar/picto_revendeur.png';

import {getColorWithState} from "../util/Toolbox";

import AnalyseTraitement from "./AnalyseTraitement";

import './css/analyseResume.css';

import {
  CaretUpOutlined,
  DownloadOutlined,
  ShareAltOutlined,
  LoadingOutlined
} from '@ant-design/icons';



 class AnalyseResume extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      VolumeBassin : '',
      TypeFiltration : '',
      TypeDesinfection : '',
      TemperatureEau : '',
      IsTempsFiltrationClicked : true,
      IsStabilisantClicked : true,
      IsEauClairTransparentClicked : true,
      TempsFiltrationText : '',
      StabilisantText : '',
      EauClairTransparente : '',
      ShowTempsFiltration : true,
      typeAnalyse_LIST : [],
      IsStopAnalyse: false,

      LibelleEtapeInfoStop : '',
      baseURL : '',

      loadingDownload : false,
      loadingShare : false
    }

  }


  async componentDidMount() {

    const search  = window.location.search;
    const params  = new URLSearchParams(search);
    const analyse  = params.get('analyse');

    let baseURL = getBaseURL();
    this.setState({baseURL});

    // Appel API
    this.loadAnalyse(analyse);

  }


  loadAnalyse(analyse){

    axios.get(`analyse/${analyse}/analyseItem`,
    ).then(({data}) => {

      if(data.success == 'success'){
          console.log(data.result);
          this.setState({typeAnalyse_LIST : data.result.typeAnalyse_LIST, GUID_Analyse : analyse,  IsStopFacultatif: data.result.IsStopFacultatif, LibelleEtapeInfoFacult : data.result.LibelleEtapeInfoFacult,   LibelleEtapeInfoStop : data.result.LibelleEtapeInfoStop,  IsStopAnalyse: data.result.IsStopAnalyse,    VolumeBassin: data.result.VolumeBassin, TemperatureEau : data.result.TemperatureEau, TypeFiltration : data.result.TypeFiltration, TypeDesinfection : data.result.TypeDesinfection, tempsFiltrationResult : data.result.tempsFiltrationResult , PH_Result : data.result.PH_Result, ChloreLibre_Result : data.result.ChloreLibre_Result  });
      }

    }).catch(function (error) {
      console.log("Error****:", error.message);
    });

  }



  downloadAnalyse(analyse){


    console.log(analyse);
    console.log(this.state.baseURL)

    let url = `${this.state.baseURL}/analyse/${analyse}/printAnalyse`;

    window.open(url);

  }


  async shareAnalyse(analyse) {

    console.log(analyse);
    console.log(this.state.baseURL)

    let url = `${this.state.baseURL}/analyse/${analyse}/printAnalyse`;
    console.log(this.state.baseURL + url)

    let htmlEmail = `

      Cliquer sur le lien ci dessous pour visualiser l'analyse ${analyse}
      ${url}

    `

    window.open(`mailto:?subject=Pool Solutions - Partage analyse&body=${encodeURIComponent(htmlEmail)}`);
  }


  getGoutteByEtat(etat){

    switch (etat) {
      case 'BON':
        return goutte_verte;
        break;
      case 'MOYEN':
        return goutte_orange;
        break;
      case 'MAUVAIS':
        return goutte_rouge;
        break;
      default:
      return goutte_orange;

    }

  }


  handleChangeValue = (dataType, value) => {

    this.setState({[dataType]: value});
  }


  traitementAnalyse = () =>{

    window.location.href= '/analyseTraitement?analyse='+ this.state.GUID_Analyse;
  }


  traitementFormulaire = () =>{

    window.location.href= '/forms';
  }


  traitementFacultatif = () =>{

    //window.location.href= '/forms';
    this.setState({IsStopFacultatif : false})
  }


  click_IsTempsFiltrationClicked = () => {

    this.setState({IsTempsFiltrationClicked : true});
  }


  click_Stabilisant = () => {

    this.setState({IsStabilisantClicked : true});
  }


  click_EauClairTransparent = () => {

    this.setState({IsEauClairTransparentClicked : true});
  }


  showModal = () => {
    //  setIsModalOpen(true);
    };

  handleOk = () => {
    //  setIsModalOpen(false);
    };

  handleCancel = () => {
    //  setIsModalOpen(false);
    };


  render() {


      return (

        <div className = "wrapper_analyse">

          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>

          <div className = "wrapper_analyse_header">

          { this.state.IsStopAnalyse == true &&
            <Modal
              title="Avertissement"
              open={this.state.IsStopAnalyse}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              footer={[

              ]}
              >
              <p>{this.state.LibelleEtapeInfoStop}</p>
              <div style = {{textAlign : 'center'} }>
                  <Button className = 'buttonFormStyle' onClick = {this.traitementFormulaire}  size={"large"} style={{fontWeight:600, paddingLeft:40, paddingRight:40, backgroundColor: '#0093CF', color:'white'}}>Nouvelle analyse</Button>
              </div>
            </Modal>
          }

          { this.state.IsStopFacultatif == true &&
            <Modal
              title="Avertissement"
              open={this.state.IsStopFacultatif}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              footer={[

              ]}
              >
              <p>{this.state.LibelleEtapeInfoFacult}</p>
              <div style = {{textAlign : 'center'} }>
                  <Button className = 'buttonFormStyle' onClick = {this.traitementFacultatif}  size={"large"} style={{fontWeight:600, paddingLeft:40, paddingRight:40, backgroundColor: '#0093CF', color:'white'}}>Continuer</Button>
              </div>
            </Modal>
          }



            <img loading="lazy" src={iconeHeader} />

            <p className = "wrapper_analyse_header_Title">Analyse</p>

            <div className = "header_image_goutte">
              <img loading="lazy" src={goutte_eau_header} />
            </div>

          </div>

          <div className= "bassin_wrapper">

          <p className = "analyse_Titre">Votre bassin</p>

          <div className = "analyse_text_containeur">

          <Row>
            <Col span = {20}>
              <p><span>{"Volume du bassin : "}</span> {this.state.VolumeBassin + " m³"}</p>
              <p><span>Type de filtration :</span> {this.state.TypeFiltration}</p>
              <p><span>Type désinfection :</span> {this.state.TypeDesinfection}</p>
              <p><span>Température de l'eau :</span> {this.state.TemperatureEau + "°C"}</p>
            </Col>
            <Col span = {4} >
              <div>
                <img loading="lazy" style = {{marginTop:25}} src={picto_analyse_bleu} />
              </div>
            </Col>
          </Row>

          </div>

          <p className = "analyse_Titre">Résultat de l'analyse</p>

          <Row align = "middle">

          {
            this.state.typeAnalyse_LIST.map((item) => (

              item.IsHideResultat == 0 ?
              <Col xl={4} lg={4} md={12} sm={12} xs={12} className = "equilibre_cell" >
                <div className = "equilibre_cell_imageTitre1">{item.LibelleTypeAnalyse}</div>
                <div className = "equilibre_cell_imageTitre2">{item.Valeur + ' ' + item.Unite }</div>
                <img loading="lazy" width="120px" height="120px" src={this.getGoutteByEtat(item.CONST_ETAT)} />
                <div className = "equilibre_cell_text"><span>idéal: </span>{item.ValMini + ' - ' + item.ValMaxi + ' ' + item.Unite } </div>
              </Col>
              : null
          ))
          }

          </Row>

            <div style={{cursor: 'pointer'}} onClick = {this.handleChangeValue.bind(this, 'ShowTempsFiltration')}>
              <p className = "analyse_Titre">Temps de filtration <CaretUpOutlined style={{color: getColorWithState(this.state.tempsFiltrationResult ? this.state.tempsFiltrationResult.tempsFiltrationEtat : '') , fontSize: '20px', cursor: 'pointer'}} type="edit"/></p>
                <div className = "analyse_text_containeur">
                  <p className = "resumeTitre">{this.state.tempsFiltrationResult ? this.state.tempsFiltrationResult.tempsFiltrationTitre : '' }</p>
                  <p>{this.state.tempsFiltrationResult ? this.state.tempsFiltrationResult.tempsFiltrationDescription : ''}</p>
                </div>
            </div>

            {
              this.state.typeAnalyse_LIST.map((item) => (


                <div style={{cursor: 'pointer'}} onClick = {this.handleChangeValue.bind(this, 'ShowTempsFiltration')}>
                  {
                    item.ListEtapeAnalyse.length > 0 &&
                      <p className = "analyse_Titre">{item.LibelleTypeAnalyse} <CaretUpOutlined style={{color: getColorWithState(item.CONST_ETAT) , fontSize: '20px', cursor: 'pointer'}} type="edit"/></p>
                  }

                  {
                    item.ListEtapeAnalyse.map((itemEtape) => (
                      <div className = "analyse_text_containeur">
                        <p className = "resumeTitre">{}</p>
                        <p>{itemEtape.LibelleEtapeInfo}</p>
                      </div>
                    ))
                  }
                </div>

            )
          )
            }

            <div className = "analyseResume_border">
            </div>
            {



              <Row>


                <Col span = {12} style= {{cursor: 'pointer', textAlign:'center', color : '#0093CF', fontSize : 35}}>
                  <span onClick={() => this.downloadAnalyse(this.state.GUID_Analyse)} > { this.state.loadingDownload == false ? <DownloadOutlined /> : <LoadingOutlined />}  </span>
                  <p className = "analyseResume_border_text">Télécharger analyse</p>
                </Col>

                <Col span = {12} style= {{cursor: 'pointer', textAlign:'center', color : '#0093CF', fontSize : 35}}>
                  <span onClick={() => this.shareAnalyse(this.state.GUID_Analyse)} > { this.state.loadingShare == false ? <ShareAltOutlined /> : <LoadingOutlined />}  </span>
                  <p className = "analyseResume_border_text">Partager analyse</p>
                </Col>
              </Row>
            }


          <div style = {{marginTop:30, marginBottom:10, textAlign : 'center'} }>
              <Button className = 'buttonFormStyle' onClick = {this.traitementAnalyse}  size={"large"} style={{width: "250px",fontWeight:600,   backgroundColor: '#0093CF', color:'white'}}>Traiter votre bassin</Button>
          </div>

          <div style = {{marginTop:10, marginBottom:20, textAlign : 'center'} }>
              <Button className = 'buttonFormStyle' onClick = {this.traitementFormulaire}  size={"large"} style={{width: "250px", fontWeight:600, marginBottom: 100, backgroundColor: '#0093CF', color:'white'}}>Nouvelle analyse</Button>
          </div>

        </div>

        <div style={{ backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundImage: `url(${navBarBackGround})`    }} className="navbar">
              <div className="container1">
                <div className="element1"><a href="/forms" className="active"><img loading="lazy"  width="50px" height="50px" src={navBarPicto_analyse} /></a></div>
                <div className="element1"><a href="/simulateur"><img loading="lazy" width="50px" height="50px" src={navBarPicto_calcul_volume_eau} /></a></div>
                <div className="element1"><a href="/"><img loading="lazy" width="50px" height="50px" src={navBarPicto_home} /></a></div>
                <div className="element1"><a href="/historique"><img loading="lazy" width="50px" height="50px" src={navBarPicto_Historique} /></a></div>
                <div className="element1"><a target="_blank" href="https://www.hydrapro.fr/app/uploads/storelocator0106/wordpress/hydrapro/index.html">  <img loading="lazy" width="50px" height="50px" src={navBarPicto_Revendeur} /></a></div>
              </div>
        </div>

        </div>


      )

    };
}


/*

<Row>
  <Col span = {12} style= {{cursor: 'pointer', textAlign:'center', color : '#0093CF', fontSize : 35}}>
    <span onClick={() => this.downloadAnalyse(this.state.GUID_Analyse)} > { this.state.loadingDownload == false ? <DownloadOutlined /> : <LoadingOutlined />}  </span>
    <p className = "analyseResume_border_text">Télécharger analyse</p>
  </Col>
*/
export default AnalyseResume;
