import React from 'react';

import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './containers/Home';
import AvaApp from './containers/AvaApp';
import AvaForm from './containers/AvaForm';
import AvaForms from './containers/AvaForms';
import SimulateurVolume from './containers/SimulateurVolume';
import Historique from './containers/Historique';

import AnalyseResume from './containers/AnalyseResume';
import AnalyseTraitement from './containers/AnalyseTraitement';
import AnalyseTraitementFamille from './containers/AnalyseTraitementFamille';
import AnalyseProduitsAll from './containers/AnalyseProduitsAll';
import AnalyseProduitDetail from './containers/AnalyseProduitDetail';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<AvaApp />}>
        <Route index element={<Home />} />
        <Route path="forms" element={<AvaForms />}>
          <Route path=":formId" element={<AvaForm />} />
        </Route>
        <Route path="simulateur" element={<SimulateurVolume />}>
        </Route>
        <Route path="historique" element={<Historique />}>
        </Route>
        <Route path="analyse" element={<AnalyseResume />}>
        </Route>
        <Route path="analyseTraitement" element={<AnalyseTraitement />}>
        </Route>
        <Route path="analyseTraitementFamille" element={<AnalyseTraitementFamille />}>
        </Route>
        <Route path="analyseProduitsAll" element={<AnalyseProduitsAll />}>
        </Route>
        <Route path="produit" element={<AnalyseProduitDetail />}>
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);




/*
https://stackblitz.com/edit/react-pckgd8?file=src%2Fcontainers%2FAvaApp.js
*/
/*

<Route path="forms" element={<AvaForms />}>
  <Route path=":formId" element={<AvaForm />} />
</Route>
<Route path="settings" element={<Settings />} />
<Route path="help" element={<HelpPage />} />
*/

/*
function App() {
  return (

    <div style={{
      display: 'block', width: 700, padding: 30
    }}>
      <h4>Gestion application HYDRAPRO</h4>




    </div>


  );
}
*/



/*
<Menu
  defaultOpenKeys={['1']}
  defaultSelectedKeys={['1']}
  style={{ width: 300 }}
  mode="inline"
>

  <Menu.Item key="1">
    <Link to="/remise">
      <span>Gestion articles</span>
    </Link>
  </Menu.Item>
  <Menu.Item key="1">
    <span>Configuration</span>
  </Menu.Item>

</Menu>
*/

export default App;
