import React from 'react';

import {  Table, Tag ,Modal, message , Auxiliary, Row, Col, Form, Input, Button, Select, Collapse} from 'antd';

import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes
} from "react-router-dom";

import axios from '../util/Api'

import {getTypeArticleOption} from "../util/Toolbox";

import iconeHeader from './assets/analyseTraitement/picto_traitement.svg';
import goutte_eau_header from './assets/analyseFormulaire/goutte_eau_header.svg';


import './css/navBar.css';
import navBarBackGround from './assets/navBar/footer_vague_bleue.svg';
import navBarPicto_analyse from './assets/navBar/picto_analyse.png';
import navBarPicto_calcul_volume_eau from './assets/navBar/picto_calcul_volume_eau.png';
import navBarPicto_home from './assets/navBar/picto_home.png';
import navBarPicto_Historique from './assets/navBar/picto_historique.png';
import navBarPicto_Revendeur from './assets/navBar/picto_revendeur.png';

import ALGICIDE from './assets/analyseTraitement/ALGICIDE.svg';
import ANTI_CALCAIRE from './assets/analyseTraitement/ANTI_CALCAIRE.svg';
import BROME from './assets/analyseTraitement/BROME.svg';
import CHLORE_CHOC from './assets/analyseTraitement/CHLORE_CHOC.svg';
import CHLORE from './assets/analyseTraitement/CHLORE.svg';
import CHLORIT from './assets/analyseTraitement/CHLORIT.svg';
import CLARIFIANT from './assets/analyseTraitement/CLARIFIANT.svg';
import O_ACTIVE from './assets/analyseTraitement/O_ACTIVE.svg';
import PH_MOINS from './assets/analyseTraitement/PH_MOINS.svg';
import PH_PLUS from './assets/analyseTraitement/PH_PLUS.svg';
import TAC_MOINS from './assets/analyseTraitement/TAC_MOINS.svg';
import TAC_PLUS from './assets/analyseTraitement/TAC_PLUS.svg';
import TH from './assets/analyseTraitement/TH.svg';
import STABILISANT from './assets/analyseTraitement/STABILISANT.svg';

import {getColorWithState} from "../util/Toolbox";


import './css/analyseTraitement.css';

import {
  EditOutlined,
  SearchOutlined,
  CloudDownloadOutlined,
  ShareAltOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  CaretRightOutlined

} from '@ant-design/icons';



 class AnalyseTraitement extends React.Component {

  constructor(props) {

    super(props);

    this.state = {

      traitement_LIST : [],
      GUID_Analyse : '',
      IndexCurrentEtape : 0

    }

  }


  async componentDidMount() {

    const search  = window.location.search;
    const params  = new URLSearchParams(search);
    const analyse  = params.get('analyse');



    // Appel API
    this.loadAnalyse(analyse);

  }


  loadAnalyse(analyse){

    axios.get(`analyse/${analyse}/analyseItem`,
    ).then(({data}) => {
      console.log(data);

      if(data.success == 'success'){
          this.setState({IndexCurrentEtape : data.result.IndexCurrentEtape,  GUID_Analyse : data.result.GUID_Analyse, traitement_LIST : data.result.traitement_LIST });
      }

    }).catch(function (error) {
      console.log("Error****:", error.message);
    });

  }


  handleChangeValue = (dataType, value) => {
    console.log('handleChangeValue')
    this.setState({[dataType]: value});
  }


  getImageTraitement(pe_libelle){

    switch (pe_libelle) {

      case 'TAC_PLUS':
        return TAC_PLUS;
        break;

      case 'ALGICIDE':
        return ALGICIDE;
        break;

      case 'ANTI_CALCAIRE':
        return ANTI_CALCAIRE;
        break;

      case 'BROME':
        return BROME;
        break;

      case 'CHLORE_CHOC':
        return CHLORE_CHOC;
        break;

      case 'CHLORE':
        return CHLORE;
        break;

      case 'CHLORIT':
        return CHLORIT;
        break;

      case 'CLARIFIANT':
        return CLARIFIANT;
        break;

      case 'O_ACTIVE':
        return O_ACTIVE;
        break;

      case 'PH_MOINS':
        return PH_MOINS;
        break;

      case 'PH_PLUS':
        return PH_PLUS;
        break;

      case 'TAC_MOINS':
        return TAC_MOINS;
        break;

      case 'TAC_PLUS':
        return TAC_PLUS;
        break;

      case 'TH':
        return TH;
        break;

      case 'STABILISANT':
        return STABILISANT;
        break;


      default:

    }


  }


  validationTraitementCell = (IDFamille, type) => {

      for (var i = 0; i < this.state.traitement_LIST.length; i++) {

        if(this.state.traitement_LIST[i].IDFamille == IDFamille){

            if(i == this.state.IndexCurrentEtape){
              window.location.href= '/analyseTraitementFamille?analyse='+ this.state.GUID_Analyse+ '&id='+IDFamille;
            }
        }
      }
  };


  retourVersResumeAnalyse = () =>{

    window.location.href= '/analyse?analyse='+ this.state.GUID_Analyse;
  }


  retourVersStoreLocator = () =>{

    window.open('https://www.hydrapro.fr/app/uploads/storelocator0106/wordpress/hydrapro/index.html', '_blank');

  }



  render() {


      return (

        <div className = "wrapper_analyse">

          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>

          <div className = "wrapper_analyse_header">

            <img loading="lazy"  src={iconeHeader} />

            <p className = "wrapper_analyse_header_Title">Traitement</p>

            <div className = "header_image_goutte">
              <img loading="lazy" src={goutte_eau_header} />
            </div>

          </div>

          <div className= "bassin_wrapper">

          <Row style = {{marginTop: 20}} gutter={[8, 0]}>
          {
              this.state.traitement_LIST.map((item, i) => (

                <Col xl={2} lg={3} md={6}  sm={8} xs={8} style={{ cursor: 'pointer'}} className="gutter-row" span={8} onClick= {this.validationTraitementCell.bind(this,item.IDFamille)}>
                  <div style={{ opacity :  i == this.state.IndexCurrentEtape ? 1 : 0.5 }} className= "traitementAnalyse_cell">
                    <img loading="lazy" width="100%" height="100%" src={this.getImageTraitement(item.LibelleImage)} />
                    <p style = {{marginTop:0}}>{'Etape ' + (i+ 1)}</p>
                  </div>
                </Col>
            ))
          }
          </Row>


          <div style = {{marginTop:20, textAlign : 'center'} }>
              <Button className = 'buttonFormStyle' onClick = {this.retourVersStoreLocator }  size={"large"} style={{width: "250px", fontWeight:600, paddingLeft:40, paddingRight:40, marginBottom: 16, backgroundColor: '#0093CF', color:'white'}}>Trouver un revendeur</Button>
          </div>

          <div style = {{marginTop:20, marginBottom:90, textAlign : 'center'} }>
              <Button className = 'buttonFormStyle' onClick = {this.retourVersResumeAnalyse}  size={"large"} style={{width: "250px", fontWeight:600, paddingLeft:40, paddingRight:40, marginBottom: 16, backgroundColor: '#0093CF', color:'white'}}>Retour à l'analyse</Button>
          </div>




        </div>

        <div style={{ backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundImage: `url(${navBarBackGround})`    }} className="navbar">
              <div className="container1">
                <div className="element1"><a href="/forms" className="active"><img loading="lazy" width="50px" height="50px" src={navBarPicto_analyse} /></a></div>
                <div className="element1"><a href="/simulateur"><img loading="lazy" width="50px" height="50px" src={navBarPicto_calcul_volume_eau} /></a></div>
                <div className="element1"><a href="/"><img loading="lazy" width="50px" height="50px" src={navBarPicto_home} /></a></div>
                <div className="element1"><a href="/historique"><img loading="lazy" width="50px" height="50px" src={navBarPicto_Historique} /></a></div>
                <div className="element1"><a target="_blank" href="https://www.hydrapro.fr/app/uploads/storelocator0106/wordpress/hydrapro/index.html">  <img loading="lazy" width="50px" height="50px" src={navBarPicto_Revendeur} /></a></div>
              </div>
        </div>


      </div>

      )

    };
}



export default AnalyseTraitement;
